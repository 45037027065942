<header>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-start">
          <a [routerLink]="['/']" class="d-flex align-items-center page-header__logo">
            <app-icon class="page-header__icon" icon="logo" color="blue-darken"></app-icon>
            <span class="page-header__title">E-Library</span>
            <span class="page-header__title-beta">BETA</span>
          </a>
          <div class="d-flex align-items-center page-header__menu" *ngIf="headerMenu$ | async as headerMenu">
            <a *ngFor="let item of headerMenu.items" [routerLink]="[item.uri]" class="page-header__menu-item">{{item.title}}</a>
            <a *ngIf="profile$ | async as profile" [routerLink]="[]" [matMenuTriggerFor]="userMenu" class="page-header__menu-item">{{profile?.displayName}} <mat-icon class="expand-icon">expand_more</mat-icon></a>
          </div>
        </div>
        <nav class="d-flex align-items-center" *ngIf="mainMenu$ | async as menu">
          <a class="navigation-item" [routerLink]="['/']">
            <app-icon icon="home" color="blue-darken"></app-icon>
          </a>
          <ng-container *ngFor="let item of menu.items">
            <a *ngIf="item.below?.length" class="navigation-item" [matMenuTriggerFor]="subMenu">
              {{item.title}} <mat-icon class="expand-icon">expand_more</mat-icon>
              <mat-menu #subMenu="matMenu">
                <a *ngFor="let subItem of item.below" mat-menu-item [routerLink]="[subItem.uri]">{{subItem.title}}</a>
              </mat-menu>
            </a>
            <a *ngIf="!item.below?.length" class="navigation-item" [routerLink]="[item.uri]">{{item.title}}</a>
          </ng-container>
          <span class="navigation-item my-e-library" (click)="openMyLibrary()">My Favorites</span>
        </nav>
      </div>
    </div>
  </div>
</header>

<mat-menu #userMenu="matMenu">
  <button mat-menu-item (click)="logout()">
    <span>Sign out</span>
  </button>
</mat-menu>