import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileModel } from '@app/models/profile.model';
import { BehaviorSubject, map, mergeMap, Observable, of, ReplaySubject, take, tap } from 'rxjs';
import { AuthApiService } from './api/auth-api.service';
import { ProfileApiService } from './api/profile-api.service';

@Injectable()
export class AuthService {
  private accessToken: ReplaySubject<string | null> = new ReplaySubject<string | null>(1);
  private profile: BehaviorSubject<ProfileModel | null> = new BehaviorSubject<ProfileModel | null>(null);

  private readonly JWT_ACCESS_LC_KEY = 'fia-library-jwt-key';

  constructor(
    private readonly authApiService: AuthApiService,
    private readonly router: Router,
    private readonly profileApiService: ProfileApiService) {
    this.init();
  }

  public login(data: { username: string, password: string }): Observable<void> {
    return this.authApiService.login(data.username, data.password)
      .pipe(
        map((data: { token: string }) => {
          this.setAccessToken(data.token);
          this.accessToken.next(data.token);
        })
      );
  }

  public changeTermsAndConditionsAcceptence(value: boolean): Observable<void> {
    return this.getCurrentUserProfile()
      .pipe(
        take(1),
        mergeMap(current => {
          if (!current) {
            return of();
          }
        
          return this.profileApiService.updateProfileTermsAndConditions(value);
        }),
        tap(() => {
          this.updateProfile({ termsAndConditions: value });
        })
      );
  }

  public updateProfile(data: Partial<ProfileModel>): void {
    const current = this.profile.value;
  
    if (!current) {
      return;
    }

    Object.assign(current, { ...data });
    this.profile.next(current);
  }

  public getCurrentUserProfile(): Observable<ProfileModel | null> {
    if (this.profile.value) {
      return this.profile.asObservable();
    }

    return this.profileApiService.getProfile()
      .pipe(
        tap(profile => this.profile.next(profile))
      );
  }

  public logout(): void {
    this.accessToken.next(null);
    this.profile.next(null);
    this.clearAccesToken();
    this.router.navigate(['/login']);
  }

  public isLoggedIn(): Observable<boolean> {
    return this.accessToken.asObservable().pipe(
      map(token => !!token)
    );
  }

  public getAccessToken(): string | null {
    const accessToken = localStorage.getItem(this.JWT_ACCESS_LC_KEY);

    return accessToken;
  }

  private setAccessToken(token: string): void {
    localStorage.setItem(this.JWT_ACCESS_LC_KEY, token);
  }

  private clearAccesToken(): void {
    localStorage.removeItem(this.JWT_ACCESS_LC_KEY);
  }

  private init(): void {
    const token = this.getAccessToken();
    this.accessToken.next(token);
  }
}
