import { Pipe, PipeTransform } from '@angular/core';
import { DocumentModel } from '@app/models/document.model';

@Pipe({
  name: 'filterFavorite'
})
export class FilterFavoritePipe implements PipeTransform {

  transform(value: DocumentModel[], favorite: boolean = true, triggerChangeDetection?: number): DocumentModel[] {
    if (!value) {
      return value;
    }

    return value.filter(item => item.favorite === favorite);
  }

}
