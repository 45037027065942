import { Injectable } from '@angular/core';
import { DocumentModel } from '@app/models/document.model';
import { DocumentFormatType } from '@app/models/enum/document-format.enum';
import { ExternalDocumentModel } from '@app/models/external-document.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor() { }

  public favoriteRemoved: Subject<DocumentModel> = new Subject();

  public convertExternalDocumentToDocument(externalDocument: ExternalDocumentModel): DocumentModel {
    const document: DocumentModel = {
      id: externalDocument.externalDocumentId,
      label: externalDocument.title,
      summary: '',
      favorite: false,
      format: DocumentFormatType.DOCUMENT,
      image: externalDocument.image,
      language: [],
      date: {id: 0, label: 0},
      type: {id: externalDocument.type?.item?.id || '', label: externalDocument.type?.item?.title || ''},
      issuingEntity: {id: '', label: ''},
      tags: externalDocument.tags
    }

    return document;
  }
}
