import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { GuestGuard } from './core/guards/guest.guard';
import { TermsAndConditionGuard } from './core/guards/terms-and-condition.guard';
import { AuthPageComponentComponent } from './pages/auth-page-component/auth-page-component.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'terms-and-conditions',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
  },
  {
    path: '',
    canActivate: [AuthGuard, TermsAndConditionGuard],
    component: AuthPageComponentComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/basic-page/basic-page.module').then(m => m.BasicPageModule)
      },
      {
        path: 'library',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/library-list/library-list.module').then(m => m.LibraryListModule)
          },
          {
            path: ':id',
            loadChildren: () => import('./pages/library-file/library-file.module').then(m => m.LibraryFileModule)
          }
        ]
      },
      {
        path: 'page',
        loadChildren: () => import('./pages/basic-page/basic-page.module').then(m => m.BasicPageModule)
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/home'
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
