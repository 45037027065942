import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private readonly authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => this.handleError(err))
    );
  }


  private handleError(err: HttpErrorResponse): Observable<never> {
    if (!(err instanceof HttpErrorResponse)) { 
      return throwError(() => err);
    }

    const jwt = this.authService.getAccessToken();

    switch (err.status) {
      case 401:
        if (jwt) {
          this.authService.logout();
        }

        break;
    }

    return throwError(() => err);
  }
}
