import { CommonModule } from '@angular/common';
import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { HeaderComponent } from './components/header/header.component';
import { IconComponent } from './components/icon/icon.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ServerErrorMessageComponent } from './components/server-error-message/server-error-message.component';
import { SortOrderSelectorComponent } from './components/sort-order-selector/sort-order-selector.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentInfoModalComponent } from './components/document-info-modal/document-info-modal.component';
import { DocumentIconsIndicatorsComponent } from './components/document-icons-indicators/document-icons-indicators.component';
import { FooterComponent } from './components/footer/footer.component';
import { TagComponent } from './components/tag/tag.component';
import { DocumentCardComponent } from './components/document-card/document-card.component';
import { CollectionCardComponent } from './components/collection-card/collection-card.component';
import { MyLibraryDialogComponent } from './components/my-library-dialog/my-library-dialog.component';
import { TrustHtmlPipe } from './pipes/trust-html.pipe';
import { FilterFavoritePipe } from './pipes/filter-favorite.pipe';
import { FilterLabelPipe } from './pipes/filter-label.pipe';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

const COMPONENTS = [
  LoadingButtonComponent,
  IconComponent,
  SortOrderSelectorComponent,
  PaginationComponent,
  ServerErrorMessageComponent,
  DateRangePickerComponent,
  HeaderComponent,
  DocumentInfoModalComponent,
  DocumentIconsIndicatorsComponent,
  FooterComponent,
  TagComponent,
  DocumentCardComponent,
  CollectionCardComponent,
  MyLibraryDialogComponent,
  BreadcrumbsComponent
];

const DIRECTIVES = [] as any[];

const PIPES = [
  TrustHtmlPipe,
  FilterFavoritePipe,
  FilterLabelPipe
];

const ANGULAR_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule
];

const OTHER_MODULES = [
  MatButtonModule,
  MatInputModule,
  MatDatepickerModule,
  MatIconModule,
  MatProgressSpinnerModule,
  NgSelectModule,
  NgScrollbarModule,
  MatMenuModule,
  MatDialogModule,
  MatCheckboxModule
];

const PROVIDERS = [] as Provider[];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  imports: [
    ...ANGULAR_MODULES,
    ...OTHER_MODULES
  ],
  exports: [
    ...ANGULAR_MODULES,
    ...OTHER_MODULES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  providers: [
    ...PROVIDERS
  ],
  schemas: []
})
export class SharedModule {
}
