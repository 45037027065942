<a *ngIf="document" class="document-card" [ngClass]="layout" [routerLink]="['/library', document.id]">
  <div class="image-wrapper">
    <img *ngIf="document.image" [src]="document.image.url" [attr.alt]="document.image.alt">
  </div>
  <div class="d-flex flex-column mt-auto">
    <div class="title-wrapper">
      <h3>{{document.label}}</h3>
      <div *ngIf="showFavoriteButton" class="favorite-toggle" (click)="toggleFavorite($event)">
        <app-icon *ngIf="!isFavoriteLoading; else favoriteLoadingTmp" icon="star" [color]="document.favorite ? 'yellow' : 'grey-D9'"></app-icon>
        <ng-template #favoriteLoadingTmp>
          <mat-spinner [diameter]="16" class="m-0"></mat-spinner>
        </ng-template>
      </div>
    </div>
    <p *ngIf="document.summary" [innerHtml]="document.summary"></p>
    <div class="tag-list" *ngIf="document.tags?.items?.length || document.type.label">
      <app-tag *ngFor="let tag of document.tags?.items || []" size="small">{{tag.title}}</app-tag>
      <app-tag *ngIf="document.type?.label" size="small" color="pink">{{document.type.label}}</app-tag>
    </div>
  </div>
</a>