import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AvailableDocumentFilters } from '@app/models/availiable-documents-filters.model';
import { BaseResponseDto } from '@app/models/base-response-dto.model';
import { DocumentFiltersModel } from '@app/models/document-filters.model';
import { DocumentModel } from '@app/models/document.model';
import { DocumentFormatType } from '@app/models/enum/document-format.enum';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';

@Injectable()
export class DocumentsApiService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private readonly httpService: HttpClient) { }

  public getAvailableDocumentFilters(): Observable<AvailableDocumentFilters> {
    return this.httpService.get<AvailableDocumentFilters>(`${this.apiUrl}/api/document-filters`);
  }

  public getDocumentStream(id: string, format: DocumentFormatType): Observable<Blob> {
    const params = new HttpParams().set('format', format);
  
    return this.httpService.get(`${this.apiUrl}/api/documents/${id}/stream`, {
      responseType: 'blob',
      observe: 'response',
      params
    }).pipe(
      map(({ body, headers }: HttpResponse<any>) => {
        const blob = new Blob([body], { type: headers.get('content-type') || undefined });
        return blob;
      })
    )
  }

  public getDocumentById(id: string): Observable<DocumentModel> {
    return this.httpService.get<DocumentModel>(`${this.apiUrl}/api/documents/${id}`);
  }

  public getDocuments(filters: Partial<DocumentFiltersModel> = { }, offset = 0, limit = 10, sort = ''): Observable<BaseResponseDto<DocumentModel>> {
    let params = new HttpParams();
    Object.entries(filters).forEach(([key, value]) => {
      const isArray = Array.isArray(value);
      if ((isArray && value.length) || (!isArray && value)) {
        params = params.set(key, value.toString());
      }
    });

    params = params
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('sort', sort);

    return this.httpService.get<BaseResponseDto<DocumentModel>>(`${this.apiUrl}/api/documents`, { params });
  }

  public getFavorites(): Observable<BaseResponseDto<DocumentModel>> {
    return this.httpService.get<BaseResponseDto<DocumentModel>>(`${this.apiUrl}/api/favorite`);
  }

  public addToFavorites(id: string): Observable<any> {
    return this.httpService.post(`${this.apiUrl}/api/favorite/${id}`, {});
  }

  public removeFromFavorites(id: string): Observable<any> {
    return this.httpService.delete(`${this.apiUrl}/api/favorite/${id}`);
  }
}
