import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AuthApiService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private readonly httpClient: HttpClient) { }

  public login(username: string, password: string): Observable<{ token: string }> {
    const b64Encoded = window.btoa(decodeURIComponent(encodeURIComponent(`${username}:${password}`)));
    const headers = new HttpHeaders({
      Authorization: `Basic ${b64Encoded}`
    });
    return this.httpClient.get<{ token: string }>(`${this.apiUrl}/jwt/token`, { headers })
  }
}
