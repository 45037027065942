<div class="my-library-dialog">
  <h2>My Favorites</h2>
  <div class="close-button" mat-dialog-close><app-icon icon="close" color="blue-dark"></app-icon></div>
  <div *ngIf="isLoading" class="flex-grow-1 d-flex align-items-center justify-content-center">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
  <ng-scrollbar class="flex-grow-1" *ngIf="documentList$ | async as documentList">
    <div class="document-list-wrapper">
      <app-document-card *ngFor="let item of documentList | filterFavorite:true:changeDetectionTrigger" layout="list" [document]="item" (click)="close()" (favoriteChanged)="favoriteChanged($event)"></app-document-card>
    </div>
  </ng-scrollbar>
</div>
