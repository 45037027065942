import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { DocumentsApiService } from '@app/core/services/api/documents-api.service';
import { DocumentsService } from '@app/core/services/documents.service';
import { DocumentModel } from '@app/models/document.model';
import { Observable, finalize, map } from 'rxjs';

@Component({
  selector: 'app-my-library-dialog',
  templateUrl: './my-library-dialog.component.html',
  styleUrls: ['./my-library-dialog.component.scss']
})
export class MyLibraryDialogComponent implements OnInit {

  public documentList$!: Observable<any>;
  public isLoading = true;

  public changeDetectionTrigger = 0;

  constructor(
    private readonly documentsApiService: DocumentsApiService,
    private readonly documentsService: DocumentsService,
    private readonly dialogRef: DialogRef<MyLibraryDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.documentList$ = this.documentsApiService.getFavorites().pipe(
      map(res => res.items),
      finalize(() => this.isLoading = false)
    );
  }

  public close(): void {
    this.dialogRef.close();
  }

  public favoriteChanged(document: DocumentModel): void {
    this.changeDetectionTrigger = Math.random();

    if (!document.favorite) {
      this.documentsService.favoriteRemoved.next(document);
    }
  }

}
