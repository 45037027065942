<footer>
  <div class="container">
    <div class="row">
      <div class="col-5 d-flex flex-column justify-content-between">
        <div class="page-footer__logo-wrapper">
          <a href="https://www.fia.com/" target="_blank">
            <app-icon class="page-footer__logo" icon="logo" color="blue-darken"></app-icon>
          </a>
        </div>
        <small class="copyright">All rights reserved &copy; {{currentYear}}</small>
      </div>
      <div class="col-7 d-flex" *ngIf="footerMenu$ | async as footerMenu">
        <div class="menu-column" *ngIf="footerMenu.items?.length">
          <h2>Navigation</h2>
          <ul>
            <li *ngFor="let item of footerMenu.items"><a [routerLink]="[item.uri]">{{item.title}}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>