import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbModel } from '@app/models/breadcrumb.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() breadcrumbs: BreadcrumbModel[] = []
  @Input() color: string = 'blue';

  constructor() { }

  ngOnInit(): void {
  }

}
