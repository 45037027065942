import { Component, Input } from '@angular/core';
import { DocumentModel } from '@app/models/document.model';

@Component({
  selector: 'app-document-icons-indicators',
  templateUrl: './document-icons-indicators.component.html',
  styleUrls: ['./document-icons-indicators.component.scss']
})
export class DocumentIconsIndicatorsComponent {
  @Input() public document!: DocumentModel;
  @Input() public color?: string;
}
