import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent  {
  @Input() public cssClass = '';
  @Input() public loading = false;
  @Input() public disabled = false;
  @Input() public label!: string;
  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public clickHandler(): void {
    if (this.loading) {
      return;
    }

    this.clicked.emit();
  }

}
