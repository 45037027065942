import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = this.authService.getAccessToken();

    if (!jwt) {
      return next.handle(request);
    }

    const decodedJwt: { exp: number } = jwt_decode(jwt);
    const now = new Date().getTime() - 5000;

    if (decodedJwt.exp * 1000 <= now) {
      this.authService.logout();
      return EMPTY;
    }

    return next.handle(request.clone({
      headers: request.headers.set('Authorization', `Bearer ${jwt}`)
    }));
  }
}
