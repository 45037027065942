import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponseDto } from '@app/models/base-response-dto.model';
import { BreadcrumbModel } from '@app/models/breadcrumb.model';
import { MenuItemModel } from '@app/models/menu-item.model';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationApiService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private readonly httpClient: HttpClient) { }

  public getMenuById(id: string, maxDepth = 1): Observable<BaseResponseDto<MenuItemModel>> {
    const params = new HttpParams().set('maxDepth', maxDepth);
    return this.httpClient.get<BaseResponseDto<MenuItemModel>>(`${this.apiUrl}/api/menus/${id}`, { params });
  }

  public getMenuTrailByAlias(alias: string): Observable<BreadcrumbModel[]> {
    const params = new HttpParams()
      .set('path', alias)
      .set('maxDepth', 3);

    return this.httpClient.get<BaseResponseDto<MenuItemModel>>(`${this.apiUrl}/api/menus/main`, { params }).pipe(
      map(res => {
        const findActiveTrailChild = (itemList: MenuItemModel[]): BreadcrumbModel[] => {
          let trail: BreadcrumbModel[] = [];
          const activeItem = itemList.find(item => item.inActiveTrail);
          if (!activeItem) {
            return [];
          }
          const path = activeItem.uri ? activeItem.uri.split('/') : undefined;

          if (path) {
            path.shift();
            path[0] = `/${path[0] || ''}`;
          }
          
          trail.push({title: activeItem.title, path});

          if (activeItem.below?.length) {
            trail = [...trail, ...findActiveTrailChild(activeItem.below)];
          }

          return trail;
        }

        return findActiveTrailChild(res.items);
      })
    );
  }
}
