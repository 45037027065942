<div class="date-picker" #container>
  <div class="d-flex align-items-center date-control" [class.opened]="opened" (click)="togglePicker()">
    <app-icon icon="calendar" color="blue-darken"></app-icon>
  
    <div class="flex-grow-1 date-control__value">
      <ng-container *ngIf="form.controls.dateFrom.value || form.controls.dateTo.value">
        {{ form.controls.dateFrom.value }} - {{ form.controls.dateTo.value }}
      </ng-container>
    </div>
  
    <div class="date-control__arrow"></div>
  </div>

  <div class="d-flex date-picker__dialog date-selector-dialog" [class.empty]="!dates || !dates.length" *ngIf="opened">
    <ng-container *ngIf="dates && dates.length; else noOptTmp">
      <div class="d-flex flex-column date-selector-dialog__column years-column">
        <div class="years-column__label">From</div>

        <ng-scrollbar class="flex-grow-1">
          <div class="d-flex flex-column align-items-center">
            <div 
              (click)="setControlValue(form.controls.dateFrom, item.id)"
              class="years-column__item" 
              [ngClass]="{
                'years-column__item_disabled': form.controls.dateTo.value && +item.id > +form.controls.dateTo.value, 
                'years-column__item_active': form.controls.dateFrom.value === item.id }" 
              *ngFor="let item of dates">
              {{ item.label }}
            </div>
          </div>
        </ng-scrollbar>
      </div>
      <div class="date-selector-dialog__separator"></div>
      <div class="d-flex flex-column date-selector-dialog__column years-column">
        <div class="years-column__label">To</div>

        <ng-scrollbar class="flex-grow-1">
          <div class="d-flex flex-column align-items-center">
            <div 
              (click)="setControlValue(form.controls.dateTo, item.id)"
              class="years-column__item" 
              [ngClass]="{
                'years-column__item_disabled': form.controls.dateFrom.value && +item.id < +form.controls.dateFrom.value, 
                'years-column__item_active': form.controls.dateTo.value === item.id }" 
              *ngFor="let item of dates">
              {{ item.label }}
            </div>
          </div>
        </ng-scrollbar>
      </div>
    </ng-container>

    <ng-template #noOptTmp>
      <div class="date-selector-dialog__empty">
        <span>No items found</span>
      </div>
    </ng-template>
  </div>
</div>
