<button 
  class="button" 
  mat-flat-button 
  (click)="clickHandler()"
  [ngClass]="cssClass">
  <ng-container *ngIf="!loading; else spinnerTmp">
    {{ label }}
  </ng-container>

  <ng-template #spinnerTmp>
    <mat-spinner class="white-color" [diameter]="20"></mat-spinner>
  </ng-template>
</button>