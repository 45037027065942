import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfileModel } from '@app/models/profile.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ProfileApiService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private readonly httpClient: HttpClient) { }

  public getProfile(): Observable<ProfileModel> {
    return this.httpClient.get<ProfileModel>(`${this.apiUrl}/api/users/me`);
  }

  public updateProfileTermsAndConditions(termsAndCondition: boolean): Observable<void> {
    return this.httpClient.put<void>(`${this.apiUrl}/api/users/me/legal`, { termsAndCondition })
  }
}
