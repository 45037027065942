<div class="d-flex align-items-center sort-order-selector" [formGroup]="form">
  <label class="sort-order-selector__label">Sort by</label>

  <ng-select
    formControlName="propertyName"
    class="ng-select-rounded sort-order-selector__control" 
    [items]="availableSortings"
    bindLabel="propertyName"
    bindValue="propertyName"
    [disabled]="isLoading"
    [clearable]="false"
    placeholder="Sort by">

    <ng-template ng-label-tmp let-item="item">
      {{ item.propertyName | titlecase }}
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      {{ item.propertyName | titlecase }}
    </ng-template>
  </ng-select>

  <div 
    class="d-flex align-items-center justify-content-center sort-order-selector__direction" 
    (click)="changeDirection()">
    <app-icon [icon]="form.controls.direction.value === directions.ASC ? 'order_asc' : 'order_desc'" color="blue"></app-icon>
  </div>
</div>
