import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DirectionOption } from '@app/models/enum/sort-direction-option.enum';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-sort-order-selector',
  templateUrl: './sort-order-selector.component.html',
  styleUrls: ['./sort-order-selector.component.scss']
})
export class SortOrderSelectorComponent implements OnInit {
  @Input() public isLoading = false;
  @Input() public availableSortings: { propertyName: string }[] = [];
  @Input() public sort?: string;

  @Output() public sortChanged: EventEmitter<string> = new EventEmitter();

  public readonly directions: typeof DirectionOption = DirectionOption;

  public form = new FormGroup({
    direction: new FormControl<string>(DirectionOption.ASC, { nonNullable: true }),
    propertyName: new FormControl('', { nonNullable: true }),
  });

  constructor(
    private readonly tracker: MatomoTracker
  ) { }

  ngOnInit(): void {
    if (this.sort) {
      const [propertyName, direction] = this.sort.split(':');
      this.form.patchValue({ propertyName, direction });
    }

    this.form.valueChanges
      .subscribe(data => {
        const sort = data.propertyName ? `${data.propertyName}:${data.direction}` : '';
        this.tracker.trackEvent('Sort', 'searchSortChange', sort);
        this.sortChanged.emit(sort);
      });
  }

  public changeDirection(): void {
    if (this.isLoading) {
      return;
    }
  
    const currentDirection = this.form.controls.direction.value;
    const newDirection = currentDirection === DirectionOption.ASC ? DirectionOption.DESC : DirectionOption.ASC;

    this.form.controls.direction.patchValue(newDirection);
  }

}
