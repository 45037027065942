import { Pipe, PipeTransform } from '@angular/core';
import { AvailableDocumentFilters, AvailableDocumentFiltersList } from '@app/models/availiable-documents-filters.model';
import { FilterNameMap } from '../constants/filter-name-map.const';

@Pipe({
  name: 'filterLabel'
})
export class FilterLabelPipe implements PipeTransform {

  transform(value: string, filterName: string, availableFilters?: AvailableDocumentFilters, additionalFilters?: any): string {
    if (!availableFilters || !availableFilters?.filters) {
      return value;
    }
    
    const mappedFilterName = FilterNameMap[filterName] || filterName;
    let filterValues = availableFilters.filters[mappedFilterName as keyof AvailableDocumentFiltersList] || additionalFilters?.[mappedFilterName];
    if (!filterValues) {
      return value;
    }

    if (!Array.isArray(filterValues)) {
      filterValues = [filterValues]
    }
 
    const currentFilter: any = (filterValues as any[]).find((filterValue: any) => filterValue.id === value);
    return currentFilter.label;
  }

}
