import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import jwt_decode from 'jwt-decode';
import { map, Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const redirect = this.router.parseUrl('/login');
  
    return this.authService.isLoggedIn()
      .pipe(
        map(isLoggedIn => {
          if (!isLoggedIn) {
            return redirect;
          }

          const now = new Date().getTime();
          const jwt = this.authService.getAccessToken();

          if (!jwt) {
            return redirect;
          }

          const decodedJwt: { exp: number } = jwt_decode(jwt);
          if (decodedJwt.exp * 1000 <= now) {
            this.authService.logout();
            return redirect;
          }

          return true;
        })
      );
  }  
}
