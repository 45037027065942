<div class="document-info-modal">
  <div class="d-flex align-items-center justify-content-center document-info-modal__close" mat-dialog-close>
    <app-icon icon="close" color="white"></app-icon>
  </div>

  <div class="document-info-modal__applicable-date document-field" *ngIf="document?.applicableDate as applicableDate">
    <label class="document-field__label">Applicable date:</label>
    <span class="document-field__value">{{applicableDate | date: 'd/M/yy'}}</span>
  </div>

  <div class="document-info-modal__name">
    {{ document?.label }}
  </div>

  <div class="document-info-modal__location document-field" *ngIf="document?.locationOriginal as location">
    <label class="document-field__label">Location:</label>
    <span class="document-field__value">{{location}}</span>
  </div>

  <div class="d-flex align-items-center document-info-modal__event event-info" *ngIf="document && document?.eventName">
    <div class="event-info__field event-field">
      <label class="event-field__label">Event type:</label>
      <span class="event-field__value">{{document.eventType || '-'}}</span>
    </div>
    <div class="event-info__field event-field">
      <label class="event-field__label">Event name:</label>
      <span class="event-field__value">{{document.eventName }}</span>
    </div>
    <div class="event-info__field event-field">
      <label class="event-field__label">Event Location:</label>
      <span class="event-field__value">{{document.eventLocation || '-'}}</span>
    </div>
    <div class="event-info__field event-field">
      <label class="event-field__label">Start date:</label>
      <span class="event-field__value">{{(document.eventDateStart | date: 'd/M/yy') || '-'}}</span>
    </div>
    <div class="event-info__field event-field">
      <label class="event-field__label">End:</label>
      <span class="event-field__value">{{(document.eventDateEnd | date: 'd/M/yy') || '-'}}</span>
    </div>
  </div>

  <div class="document-info-modal__description" *ngIf="document?.summary as description">
    {{ description }}
  </div>

  <div class="document-info-modal__footer" *ngIf="document">
    <app-document-icons-indicators [document]="document" color="blue"></app-document-icons-indicators>
  </div>
</div>