import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authService.getCurrentUserProfile()
      .pipe(
        map(profile => {
          if (!profile || profile.termsAndConditions) {
            return true;
          }

          this.router.navigate(['terms-and-conditions'])
          return false;
        })
      );
  }
}
