import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { environment } from '@env/environment';
import { SharedModule } from './shared/shared.module';
import { AuthPageComponentComponent } from './pages/auth-page-component/auth-page-component.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthPageComponentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    NgxMatomoTrackerModule.forRoot({
      disabled: !environment.production,
      trackerUrl: `${environment.matomoUrl}/matomo.php`,
      siteId: '2',
      scriptUrl: `${environment.matomoUrl}/matomo.js`,

    }),
    NgxMatomoRouterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
