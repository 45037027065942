import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/core/services/auth.service';
import { ProfileModel } from '@app/models/profile.model';
import { Observable } from 'rxjs';
import { MyLibraryDialogComponent } from '../my-library-dialog/my-library-dialog.component';
import { NavigationApiService } from '@app/core/services/api/navigation-api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public profile$!: Observable<ProfileModel | null>;
  public mainMenu$!: Observable<any>;
  public headerMenu$!: Observable<any>;

  constructor(
    private readonly authService: AuthService,
    private readonly navigationApiService: NavigationApiService,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.profile$ = this.authService.getCurrentUserProfile();
    this.mainMenu$ = this.navigationApiService.getMenuById('main', 2);
    this.headerMenu$ = this.navigationApiService.getMenuById('header');
  }

  public openMyLibrary(): void {
    this.dialog.open(MyLibraryDialogComponent, {
      height: '100vh',
      panelClass: 'animate-slideInRight',
      enterAnimationDuration: '0ms',
      position: { right: '0' }
    })
  }

  public logout(): void {
    this.authService.logout();
  }

}
