<div class="d-flex flex-wrap align-items-center document-icons" [ngClass]="color">
  <div class="d-flex align-items-center document-icons__icon document-type" *ngIf="document.type as type">
    <app-icon icon="clip" color="grey"></app-icon>
    {{ type.label }}
  </div>
  <ng-container *ngIf="document.language as languages">
    <div class="d-flex align-items-center document-icons__icon" *ngIf="languages.length">
      <app-icon icon="globe" color="grey"></app-icon>
      <ng-container *ngFor="let language of languages; let last = last">
        {{ language.label }}
        <ng-container *ngIf="!last"> / </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <div class="d-flex align-items-center document-icons__icon" *ngIf="document.date as date">
    <app-icon icon="calendar" color="grey"></app-icon>
    {{ date.label }}
  </div>
  <div class="d-flex align-items-center document-icons__icon" *ngIf="document.issuingEntity as issuingEntity">
    <app-icon icon="file" color="grey"></app-icon>
    {{ issuingEntity.label }}
  </div>
</div>
