import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { map, Observable, tap } from 'rxjs';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.authService.isLoggedIn()
      .pipe(
        tap(isLoggedIn => {
          if (isLoggedIn) {
            this.router.navigate(['/library']);
          }
        }),
        map(isLoggedIn => !isLoggedIn)
      );
  }  
}
