import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @ViewChild('container') public container?: ElementRef;
  @Input() public dates: { id: string, label: string }[] = [];
  @Input() public set currentValue(filters: { dateFrom?: string | null, dateTo?: string | null }) {
    this.form.patchValue(filters, { emitEvent: false });
  }

  @Output() public dateChanged: EventEmitter<{ dateFrom?: string | null, dateTo?: string | null }> = new EventEmitter();
  public opened = false;

  public form = new FormGroup({
    dateFrom: new FormControl<string | null>(null),
    dateTo: new FormControl<string | null>(null)
  });

  constructor() { }

  ngOnInit(): void {
    this.form.valueChanges
      .subscribe(data => {
        this.dateChanged.emit(data);
      });
  }

  public togglePicker(): void {
    this.opened = !this.opened;
  }

  public setControlValue(control: FormControl, value: string): void {
    if (control.value === value) {
      control.patchValue(null);
      return;
    }
  
    control.patchValue(value);
  }

  @HostListener('window:click', ['$event'])
  public clickOutside(event: Event): void {
    const clickedInside = this.container?.nativeElement.contains(event.target);

    if (clickedInside) {
      return;
    }

    this.opened = false;
  }

}
