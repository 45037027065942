import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
  @Input() public set options(data: { currentPage: number, maxCount: number, limit: number, lastPage : number }) {
    this._options = { ...data };
    this._options.lastPage = Math.ceil(data.maxCount / data.limit);
    this.init();
  }

  public get options(): { currentPage: number, maxCount: number, limit: number, lastPage: number } {
    return this._options;
  }

  public pagesButtons: number[] = [];

  private _options!: { currentPage: number, maxCount: number, limit: number, lastPage: number };

  private readonly pagesToShow = 3;

  constructor() { }

  ngOnInit(): void {
  }

  public changePageEmitter(pageNumber: number): void {
    this.options.currentPage = pageNumber;
    this.changePage.emit(pageNumber);
  }

  public nextPage(): void {
    if (this.options.currentPage >= this.options.lastPage) {
      return;
    }

    this.options.currentPage++;
    this.changePageEmitter(this.options.currentPage);
  }

  public previousPage(): void {
    if (this.options.currentPage <= 1) {
      return;
    }

    this.options.currentPage--;
    this.changePageEmitter(this.options.currentPage);
  }

  private init(): void {
    const firstPage = 1;
    const { lastPage, currentPage } = this._options;
    let pages = [currentPage];
    const pagesCount = Math.min(lastPage, this.pagesToShow);

    let i = 0;
    while (pages.length < pagesCount) {
      i++;
      const prevPage = currentPage - i;
      const nextPage = currentPage + i;

      if (prevPage > 0) {
        pages.unshift(prevPage);
      }

      if (nextPage <= lastPage) {
        pages.push(nextPage);
      }
    }

    pages = pages.sort((a,b) => a - b);

    if (pages.indexOf(firstPage) === -1) {
      if (pages.indexOf(firstPage + 1) === -1) {
        pages.unshift(0);
      }
    
      pages.unshift(firstPage);
    }

    if (pages.indexOf(lastPage) === -1) {
      if (pages.indexOf(lastPage - 1) === -1) {
        pages.push(0);
      }

      pages.push(lastPage);
    }

    this.pagesButtons = pages;
  }
}
