import { Component, OnInit } from '@angular/core';
import { NavigationApiService } from '@app/core/services/api/navigation-api.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public currentYear = new Date().getFullYear();
  public footerMenu$!: Observable<any>;

  constructor(
    private readonly navigationApiService: NavigationApiService
  ) { }

  ngOnInit(): void {
    this.footerMenu$ = this.navigationApiService.getMenuById('footer');
  }

}
