<div class="pagination">
  <button mat-button class="button pagination__button pagination__button--previous"
          [disabled]="options.currentPage === 1"
          (click)="previousPage()">
    <mat-icon>chevron_left</mat-icon>
  </button>

  <ng-container *ngFor="let page of pagesButtons">
    <ng-container *ngIf="page; else space">
      <button class="pagination__page" (click)="changePageEmitter(page)" [class.pagination__page_active]="page === options.currentPage">
        {{page}}
      </button>
    </ng-container>

    <ng-template #space>
      <div class="pagination__space">...</div>
    </ng-template>
  </ng-container>

  <button mat-button class="button pagination__button pagination__button--next"
          [disabled]="options.currentPage === options.lastPage"
          (click)="nextPage()">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>