import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CollectionModel } from '@app/models/collection.model';
import fitty from 'fitty';

@Component({
  selector: 'app-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss']
})
export class CollectionCardComponent implements AfterViewInit {

  @Input() collection!: CollectionModel;

  @ViewChild('cardContentEl') cardContentEl?: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    fitty(this.cardContentEl?.nativeElement, { minSize: 20, maxSize: 44 });
  }

}
