import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentsApiService } from '@app/core/services/api/documents-api.service';
import { DocumentModel } from '@app/models/document.model';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss']
})
export class DocumentCardComponent implements OnInit {

  @Input() layout: 'grid'|'list' = 'grid';
  @Input() document!: DocumentModel;
  @Input() showFavoriteButton = true;

  @Output() favoriteChanged: EventEmitter<DocumentModel> = new EventEmitter();

  public isFavoriteLoading = false;

  constructor(
    private readonly documentsApiService: DocumentsApiService
  ) { }

  ngOnInit(): void {
  }

  public toggleFavorite(event: any): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.isFavoriteLoading) {
      return;
    }

    const request$ = this.document.favorite
      ? this.documentsApiService.removeFromFavorites(this.document.id)
      : this.documentsApiService.addToFavorites(this.document.id);

    this.isFavoriteLoading = true;
    request$.pipe(
      finalize(() => this.isFavoriteLoading = false)
    ).subscribe(res => {
      this.document.favorite = !this.document.favorite;
      this.favoriteChanged.emit(this.document);
    });
  }

}
